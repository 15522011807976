.video-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.video-wrapper {
  width: 100%;
  height: 100%;
}

.video-wrapper > * {
  width: 100%;
  height: 100%;
}

.video-wrapper > * > video {
  object-fit: cover;
}
